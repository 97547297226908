<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :edit-mode="false"
  >
    <template v-if="entityLoaded">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col cols="12" md="12">
          <user-view-user-info-card
            :entity="entity"
            :endpoint="config.endpoint"
            :edit-path="`${config.route}-edit`"
          />
        </b-col>
      </b-row>
    </template>
  </EntitySingle>
</template>

<script>
import { formUtils } from '@core/mixins/ui/forms'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import storeModule from '../userStoreModule'

export default {
  components: {
    EntitySingle,
    UserViewUserInfoCard,
  },
  mixins: [formUtils],
  data() {
    return {
      entity: {},
      entityLoaded: false,
      rightsLoaded: false,
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'users',
        endpoint: 'users',
        route: 'users',
        title: {
          single: this.$t('User'),
          plural: this.$t('Users'),
        },
      }
    },
  },
  mounted() {},
  methods: {},
}
</script>
